@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Bricolage+Grotesque:opsz,wght@10..48,300;10..48,400;10..48,500;10..48,600;10..48,700;10..48,800&family=Manrope:wght@300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700&family=Montserrat:ital,wght@0,300;0,400;0,600;1,400&family=Poppins:ital,wght@0,300;0,400;0,500;1,400&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

:root {
  --gradient-text: linear-gradient(89.97deg, #ae67fa 1.84%, #f49345);
  --gradient-bar: linear-gradient(103.97deg, #ae67fa -13.86%, #f49345);
  --color-bg: #040c18;
  --color-footer: #031b34;
  --color-blog: #042c54;
  --color-text: #b1affd;
  --color-subtext: #ffba71;
  --scroll-to-height: 5px;
  --scroll-to-width: 5px;
  --color-primary: #ffd700;
  /* --color-primary: #ffae02; */
}
@layer base {
  :root {
    --color-neutral-50: theme("colors.gray.50");
    --color-primary-50: theme("colors.green.500");

    /* --color-dark: theme("colors.slate"); */
  }
}
.gold {
  --color-primary-50: theme("colors.yellow.500");
}
.dark {
  --color-primary-50: theme("colors.green.200");
}

.light {
  --neutralBg: theme("colors.white");
}
.react-datepicker__day.react-datepicker__day--disabled {
  @apply !text-gray-400 dark:!text-gray-500 !opacity-40;
}
.react-datepicker {
  border: none !important;
  @apply !bg-white dark:!bg-slate-900 !shadow-md;
}
.react-datepicker__header {
  @apply !bg-transparent !text-black dark:!text-white;
}
.react-datepicker__day {
  @apply !rounded-full !text-black dark:!text-white;
}
.react-datepicker__day--in-range {
  @apply !rounded-full  dark:!text-white !bg-green-500 !text-white;
}
.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  @apply dark:!text-white;
}
.css-1dimb5e-singleValue {
  @apply dark:!text-white !text-sm;
}
.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  @apply dark:!text-white;
}
.gradient__text {
  background: var(--gradient-text);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

body,
html {
  @apply font-poppins !font-light !text-xs  md:!text-sm;
  @apply bg-color_light  dark:bg-color_dark gold:bg-color_gold/10 dark:text-white;
}

.links-item {
  @apply relative;
}

.links-item::before {
  content: "";
  @apply w-0 h-[2px] absolute top-auto bottom-0 bg-blue-400 rounded-sm transition-all duration-500;
  left: 50%;
  transform: translateX(-50%);
}

.links-item:hover::before {
  @apply w-full;
}
.grid-flow {
  grid-template-columns: repeat(auto-fit, minmax(min(8rem, 100%), 1fr));
  display: grid;
  gap: 0.4rem;
}
.ping {
  animation: ping 1s infinite;
}
@keyframes ping {
  from {
    scale: 0.8;
  }
  to {
    scale: 1.2;
  }
}

@keyframes scaleanimation {
  from {
    transform: scale(0.9) translateY(-100%);
  }
  to {
    transform: scale(1, 1) translateY(0%);
  }
}

@media screen and (max-width: 640px) {
  .links-item::before {
    @apply left-0 translate-x-0;
  }
  @keyframes scaleanimation {
    from {
      transform: scale(0.9) translateX(-100%);
    }
    to {
      transform: scale(1, 1) translateX(0%);
    }
  }
}

.transiton-bg-color {
  /* @apply transition; */
  cursor: pointer;
  transition: background-color 0.4s ease;
}

.transition-bg:hover {
  @apply bg-slate-300;
  transition: background-color 0.4s ease;
}

.scale-navigate {
  @apply scale-0;
}

.scale-navigate.active {
  @apply scale-100;
}

/* button here */
.swiper-button-disabled {
  /* display: none !important; */
  opacity: 0.3 !important;
  transition: opacity 0.3s ease;
}
.swiper-button-prev::after,
.swiper-button-prev::before,
.swiper-button-next::after,
.swiper-button-next::before {
  content: none !important;
}
.arrows {
  overflow: hidden;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 23;
  font-size: 2rem;
  height: 2rem;
  width: 2rem;
  background: rgb(26, 25, 25, 0.67);
  display: flex;
  align-items: center;
  justify-content: center;
  @apply shadow-lg z-10 rounded-full text-white cursor-pointer;
}
.datePicker {
  width: 100% !important;
  @apply !font-semibold;
}
.arrow:is(:hover, :focus) {
  @apply bg-slate-400/25;
}

.arrow.arrow__left {
  left: 1rem;
}

.arrow.arrow__right {
  right: 1rem;
}

.testimonial {
  @apply transition-all translate-y-10 opacity-0 duration-700;
}

.testimonial.active {
  @apply opacity-100 translate-y-0;
}

.cal-width {
  --w: 400px;
  width: min(var(--w), calc(100vw - 30px));
}

.scale-info {
  animation: scaleanimation 0.4s;
}

.scrollto::-webkit-scrollbar {
  height: var(--scroll-to-height);
  width: var(--scroll-to-width);
}

.scrollto::-webkit-scrollbar-thumb {
  @apply border-slate-400 bg-[var(--color-primary-50)] border-[3px] rounded-[20px];
}

.scrollto:hover::-webkit-scrollbar-thumb {
  @apply bg-slate-400;
}
*::-webkit-scrollbar-thumb {
  @apply border-slate-400 bg-[var(--color-primary-50)] border-[3px] rounded-[20px];
}
.scrollto::-webkit-scrollbar-thumb:hover {
  @apply bg-[var(--color-primary-50)];
}
* {
  scrollbar-width: auto;
  /* scrollbar-color: #8f54a0 #ffffff; */
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  --scroll-to-width: 5px;
  background: red;
  height: var(--scroll-to-height);
  width: var(--scroll-to-width);
}

*::-webkit-scrollbar-track {
  background: red;
  --scroll-to-width: 5px;
  height: var(--scroll-to-height);
  width: var(--scroll-to-width);
}

.scale-animation {
  animation: scale-animation 1s infinite;
}

.button-add {
  perspective: 100px;
}

.icons-container .icon {
  @apply scale-0 rotate-90;
}

.icons-container .icon.active {
  animation-name: spinicon;
  animation-duration: 0.7s;
  animation-fill-mode: both;
}

@keyframes spinicon {
  to {
    @apply scale-125 rotate-0;
  }
}
.loader-ticket {
  position: relative;
  width: 120px;

  height: 55px;
  background-repeat: no-repeat;
  background-image: radial-gradient(circle 2.5px, #ff3d00 100%, transparent 0),
    radial-gradient(circle 2.5px, #ff3d00 100%, transparent 0),
    linear-gradient(#f0fda3 20px, transparent 0),
    linear-gradient(#333 90px, transparent 0),
    linear-gradient(#049b87 120px, transparent 0),
    linear-gradient(to right, #017a6a 10%, #333 10%, #333 90%, #017a6a 90%);

  background-size: 5px 5px, 5px 5px, 30px 5px, 90px 10px, 120px 45px, 100px 15px;
  background-position: 48px 20px, 60px 20px, 10px 20px, center bottom,
    center bottom, center 0;
}
.loader-ticket:before {
  content: "";
  width: 70px;
  height: 80px;
  background-color: #fff;
  background-image: linear-gradient(to bottom, #fff 50%, #f86133 51%),
    linear-gradient(to bottom, #bbb 50%, #0000 51%);
  background-size: 60px 20px, 60px 10px;
  background-repeat: no-repeat, repeat-y;
  background-position: center -5px, center 0;
  box-shadow: 0 0 10px #0003;
  position: absolute;
  left: 50%;
  transform: translatex(-50%);
  bottom: calc(100% + 30px);
  animation: loadPaper 2s ease-in infinite;
}
.loader-ticket:after {
  content: "";
  width: 70px;
  height: 90px;
  background-image: linear-gradient(to right, #fff 50%, #0000 51%),
    linear-gradient(to right, #fff 50%, #0000 51%),
    linear-gradient(to right, #fff 50%, #0000 51%),
    linear-gradient(to right, #fff 50%, #0000 51%),
    linear-gradient(to right, #fff 50%, #0000 51%),
    linear-gradient(to right, #fff 50%, #0000 51%),
    linear-gradient(to right, #fff 50%, #0000 51%),
    linear-gradient(to right, #fff 50%, #0000 51%);
  background-size: 10px 80px;
  background-position: 0 0px, 9px 5px, 18px 0px, 27px 7px, 36px 10px, 45px 5px,
    55px 0px, 64px 8px;
  background-repeat: no-repeat;
  position: absolute;
  left: 50%;
  transform: translatex(-50%);
  top: calc(100% + 6px);
  animation: disposePaper 2s ease-in infinite;
}

@keyframes loadPaper {
  0% {
    opacity: 0;
    height: 80px;
    bottom: calc(100% + 30px);
  }
  2% {
    opacity: 0;
    height: 80px;
    bottom: calc(100% + 15px);
  }
  50% {
    height: 80px;
    bottom: calc(100% - 10px);
  }
  75%,
  100% {
    height: 0px;
    bottom: calc(100% - 10px);
  }
}

@keyframes disposePaper {
  0%,
  60% {
    height: 0px;
    top: calc(100% - 9px);
    background-position: 0 0px, 9px 0, 18px 0, 27px 0, 36px 0, 45px 0, 55px 0,
      64px 0;
  }
  80% {
    height: 90px;
    top: calc(100% - 9px);
    opacity: 1;
  }
  100% {
    height: 90px;
    top: calc(100% + 25px);
    background-position: 0 0px, 9px 5px, 18px 0px, 27px 7px, 36px 10px, 45px 5px,
      55px 0px, 64px 8px;
    opacity: 0;
  }
}

@keyframes scale-animation {
  from {
    transform: scale(0.8);
  }
  to {
    transform: scale(1.3) rotateX(30deg);
  }
}
@media screen and (max-width: 599px) {
  input,
  select,
  textarea {
    @apply !text-lg cursor-pointer;
  }
}
.screen {
  width: 100%;
  height: 100%;
}
::-webkit-scrollbar {
  @apply w-2 h-2;
}
::-webkit-scrollbar-track {
  @apply bg-gray-400;
}
.css-qbdosj-Input,
.css-qbdosj-Input input,
.css-60vmsc-control,
.css-1fdsijx-ValueContainer {
  @apply !bg-transparent dark:!text-white;
}
.css-qbdosj-Input::after {
  @apply !bg-transparent dark:!text-white;
}
.react-pdf__Page {
  @apply bg-white dark:!bg-slate-900;
}
.react-pdf__Page__textContent,
.textLayer,
.react-pdf__Page__annotations,
.annotationLayer {
  @apply !hidden;
}
.css-1nmdiq5-menu {
  @apply dark:!bg-slate-900 dark:!text-white !rounded-sm !-translate-y-2;
}
.react-pdf__Page__canvas {
  @apply !max-w-full !w-full;
}
::-webkit-scrollbar-thumb {
  @apply rounded-lg border-2 border-orange-200 bg-blue-50;
}
::-webkit-scrollbar-thumb:hover {
  @apply rounded-xl bg-slate-200;
}
[data-title]:hover:after {
  opacity: 1;
  transition: all 0.1s ease 0.5s;
  visibility: visible;
}
[data-title]:after {
  content: attr(data-title);
  /* background-color: #00FF00; */
  /* color: #111; */
  /* font-size: 150%; */
  position: absolute;
  padding: 1px 5px 2px 5px;
  top: 1.6em;
  left: 30px;
  white-space: nowrap;
  /* box-shadow: 1px 1px 3px #797474; */
  opacity: 0;
  border: 1px solid #111111;
  z-index: 99999;
  visibility: hidden;
  @apply bg-gray-600 dark:bg-slate-300 text-white dark:text-white rounded-sm shadow-lg dark:shadow-black border-none text-lg;
}
[data-title] {
  position: relative;
}
.date {
  color: var(--secondary);
  font-size: 14px;
  text-transform: uppercase;
}
.overlay {
  @apply fixed w-full z-[50] !h-full inset-0 bg-slate-600/25  opacity-0 pointer-events-none invisible duration-500  transition-all;
}
.overlay.active {
  @apply opacity-100 pointer-events-auto visible;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}

.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #fff;
  margin: -4px 0 0 -4px;
}

.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}

.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}

.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}

.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}

.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}

.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}

.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}

.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}

.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}

.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}

.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}

.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}

.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}

.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}

.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}

.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}

@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}




.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.autoplay-progress {
  position: absolute;
  right: 16px;
  bottom: 16px;
  z-index: 10;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: var(--swiper-theme-color);
}

.autoplay-progress svg {
  --progress: 0;
  position: absolute;
  left: 0;
  top: 0px;
  z-index: 10;
  width: 100%;
  height: 100%;
  stroke-width: 4px;
  stroke: var(--swiper-theme-color);
  fill: none;
  stroke-dashoffset: calc(125.6 * (1 - var(--progress)));
  stroke-dasharray: 125.6;
  transform: rotate(-90deg);
}






